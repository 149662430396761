import React, {useEffect} from 'react';
import {Card, CardActionArea, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import {myStyles} from "components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useProtectedContext} from "../../../contexts/protectedContext";

function Dashboard() {
    const {t} = useTranslation();
    const {totalPoints, rankingPosition} = useProtectedContext()
    const navigate = useNavigate()

    return (
        <>
            <Container maxWidth="xl" sx={{pt:5, pb:5, minHeight:'87vh'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {t('dashboard.InfoClassement')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}/>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3}  sx={{ py: { xs: 4, md: 4 }, px: { xs: 3, md: 3 }, width: { xs: '100%', md: '100%' } }}>
                            <Typography variant="h6">{t('dashboard.PositionClassement')}</Typography>
                            <Typography variant="h3">{rankingPosition}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3}  sx={{ py: { xs: 4, md: 4 }, px: { xs: 3, md: 3 }, width: { xs: '100%', md: '100%' } }}>
                            <Typography variant="h6">{t('dashboard.Points')}</Typography>
                            <Typography variant="h3">{totalPoints}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}/>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between" spacing={3} sx={{pt:5,pb:5}}>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/wildcard')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <img width='75px'
                                         src={`images/icons/wildcard.png`}
                                         alt="wildcard"
                                    />
                                    <Typography variant={"h2"}>Wildcard</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/bonus')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <img width='75px'
                                         src={`images/icons/bonus.png`}
                                         alt="wildcard"
                                    />
                                    <Typography variant={"h2"}>Bonus</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/division')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <img width='75px'
                                         src={`images/icons/division.png`}
                                         alt="wildcard"
                                    />
                                    <Typography variant={"h2"}>Division</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/conference')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <img width='75px'
                                         src={`images/icons/conference.png`}
                                         alt="wildcard"
                                    />
                                    <Typography variant={"h2"}>Conférence</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/superbowl')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <img width='75px'
                                         src={`images/icons/superbowl.png`}
                                         alt="wildcard"
                                    />
                                    <Typography variant={"h2"}>Super Bowl</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Dashboard;