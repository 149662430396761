import React from 'react';
import {Hero, RulesSection} from "components";

function Home(props) {
    return (
        <>
            <Hero/>
            <RulesSection />
        </>
    );
}

export default Home;