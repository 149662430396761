import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import theme from "../../../theme";
import {useAuthContext} from "../../../contexts/authContext";

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

const number = {
    fontSize: 30,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
};

function RulesSection(props) {
    const { user, setUser } = useAuthContext()
    const {t} = useTranslation();
    return (
        <>
            <Box
                component="section"
                sx={{ display: 'flex', overflow: 'hidden', bgcolor: theme.palette.secondary.light }}
            >
                <Container
                    sx={{
                        mt: 10,
                        mb: 15,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
                        {t('home.RulesTitle')}
                    </Typography>
                    <div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box sx={number}>1.</Box>
                                    <Typography variant="h5" align="center">
                                        {t('home.RulesItem1')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box sx={number}>2.</Box>
                                    <Typography variant="h5" align="center">
                                        {t('home.RulesItem2')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box sx={number}>3.</Box>
                                    <Typography variant="h5" align="center">
                                        {t('home.RulesItem3')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        user?.email ? (
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                component="a"
                                href="/dashboard"
                                sx={{mt: 8}}
                            >
                                {t('home.DashboardButton')}
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                component="a"
                                href="/inscription"
                                sx={{mt: 8}}
                            >
                                {t('home.RulesButton')}
                            </Button>
                        )
                    }
                </Container>
            </Box>
        </>
    );
}

export default RulesSection;