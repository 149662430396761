import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import {Legend, Pie, PieChart} from "recharts";
import apiClient from "../../../../services/apiClient";
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import theme from '../../../../theme'
import { useTranslation } from 'react-i18next'

function CardChart({roundId}) {
    const {t} = useTranslation()
    const [games, setGames] = useState([]);


    const { isLoading, refetch: fetchStatisticPredictionByRound } = useQuery(
      ['fetchStatisticPredictionByRound'],
      async () => {
          return await apiClient.fetchStatisticPredictionByRound(roundId).then(({ data, error }) => {
              if (error) throw new Error(error)
              setGames(data)
          })
      },
      {
          enabled: false,
          onError: () => {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: t('app.ErrorSwal'),
                  confirmButtonColor: theme.palette.primary.light,
              })
          }
      }
    )


    useEffect(() => {
        fetchStatisticPredictionByRound()
    }, [])

    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <Paper sx={{p: 2,mb:5}} elevation={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Statistiques</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>

                        {
                          games.map((game, index) => {
                            const {stats} = game
                            return (
                              <Grid item xs={12} md={6} lg={4} key={index}>
                                <Box>
                                  <Typography variant="h5">{game.description}</Typography>
                                  {
                                    // Vérifie si toutes les valeurs des 'stats' sont à zéro
                                    stats[0].value !== 0 || stats[1].value !== 0 ? (
                                      <PieChart width={400} height={400}>
                                        <Pie dataKey="value" nameKey="name" isAnimationActive={false} data={stats} label  cx={200}
                                             cy={200}
                                             outerRadius={100}
                                             innerRadius={60}
                                             fill="#000" />
                                        <Legend />
                                      </PieChart>
                                    ) : (
                                      <Typography variant="h6">Aucune statisques disponible</Typography>
                                    )
                                  }
                                </Box>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default CardChart;
