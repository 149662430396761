import React from 'react';
import styled from "@emotion/styled";
import {Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import theme from "../../theme";
import {useAuthContext} from "../../contexts/authContext";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        width: 0,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
function SideMenu({open, handleDrawer}) {
    const { handleLogout } = useAuthContext()
    const {t} = useTranslation();

    return (
        <SideDrawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawer}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItemButton component="a" href="/dashboard" >
                    <ListItemIcon>
                        <DashboardIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Dashboard')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/wildcard" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/wildcard.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Wildcard')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/bonus" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/bonus.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Bonus')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/division" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/division.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Division')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/conference" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/conference.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Conference')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/superbowl" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/superbowl.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.SuperBowl')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/ranking" >
                    <ListItemIcon>
                        <img width='40px'
                             src={`images/icons/podium.png`}
                             alt="wildcard"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Ranking')}/>
                </ListItemButton>
            </List>
            <Divider />
            <List>
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon sx={{fontSize:'40px', color:'#1d1d1b'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Logout')}/>
                </ListItemButton>
            </List>
        </SideDrawer>
    );
}

export default SideMenu;