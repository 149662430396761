import React from 'react';
import {useTranslation} from 'react-i18next';
import loginImg from '../../asset/images/hero.png'
import {Box, Container} from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../theme";

const Background = styled(Box)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
});

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
    backgroundImage: `url(${loginImg})`,
    backgroundPosition: 'center',
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '90vh',
        minHeight: 500,
        maxHeight: 1300,
    },
}));

function HeroLayout(props) {
    const { children } = props;
    const {t} = useTranslation();

    return (
        <ProductHeroLayoutRoot>
            <Container
                sx={{
                    p:0,
                    mt: 3,
                    mb: 14,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {children}
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'common.black',
                        opacity: 0.5,
                        zIndex: -1,
                    }}
                />
            </Container>
        </ProductHeroLayoutRoot>
    );
}

export default HeroLayout;