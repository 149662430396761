import React from 'react';
import {useTranslation} from 'react-i18next';
import {List, ListItem, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const myStyles = {
    wrong: {
        fontSize: '30px',
        color:"red",
        paddingRight: "10px",
    },
    good: {
        fontSize: '30px',
        color:"green",
        paddingRight: "10px",
    }
}

const pwRules = [
    {
        key:1,
        rule: "min",
        label: "passwordRule.PwMoreEight"
    },
    {
        key:2,
        rule: "symbols",
        label: "passwordRule.PwSpecialCharacter"
    },
    {
        key:3,
        rule: "uppercase",
        label: "passwordRule.PwUpperCase"
    },
    {
        key:4,
        rule: "lowercase",
        label: "passwordRule.PwLowerCase"
    },
    {
        key:5,
        rule: "digits",
        label: "passwordRule.PwNumeric"
    }
]

function PasswordRule({pwdRuleError}) {
    const {t} = useTranslation();

    return (
        <List dense disablePadding={true}>
            {
                pwRules.map(r => {
                    const { key, rule, label } = r
                    return (
                        <ListItem key={key}>
                            {pwdRuleError.includes(rule) ? <CloseIcon sx={myStyles.wrong}/> : <CheckIcon sx={myStyles.good}/> }
                            <Typography variant="subtitle1" component="div">
                                {t(label)}
                            </Typography>
                        </ListItem>
                    )
                })
            }
        </List>
    );
}

export default PasswordRule;