const FormHelper = {
    email: {
        required: "formHelper.Required",
        pattern: "formHelper.ValidEmail"
    },
    required:{
        required: "formHelper.Required",
    },
};

export default FormHelper;