import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Container, Grid, Link, Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import theme from "../../theme";

function Copyright() {
    return (
        <Link color="inherit" href="#" sx={{textDecoration:'none', "&:hover":{color:theme.palette.primary.light}}}>
            {'© '}
            Playoff Fantasy
            {' '}
            {new Date().getFullYear()}
        </Link>
    );
}

function Footer() {
    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: 'primary.main', color:'white' }}
        >
            <Container sx={{ display: 'flex' }} disableGutters>
                <Grid container sx={{mt:2}}>
                    <Grid item xs={12} md={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                        >
                            <Grid item sx={{ display: 'flex' }}>
                                <Box component="a" href="https://www.facebook.com/groups/157410928220949" target="_blank" sx={{color:theme.palette.secondary.light, "&:hover":{color:theme.palette.primary.light} }}>
                                    <FacebookIcon fontSize="large"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Copyright />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption">
                            <Link href="https://www.techqueb.com" rel="sponsored" title="Techqueb" sx={{color:theme.palette.secondary.light, "&:hover":{color:theme.palette.primary.light}, textDecoration:'none' }}>
                                   TECHQUEB
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}

export default Footer;