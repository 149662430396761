import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import translationFR from './fr.json'

const fallbackLng = ['fr'];
const availableLanguages = ['fr'];
const resources = {
	// en: {
	// 	translation: translationEN
	// },
	fr: {
		translation: translationFR
	}
};

i18n
.use(XHR) // load translations using http (default                                               public/assets/locals/en/translations)
	// .use(LanguageDetector) // detect user language
	.use(initReactI18next) // pass the i18n instance to react-i18next.
	.init({
		resources,
		fallbackLng,
		detection: {
			checkWhitelist: true, // options for language detection
		},
		debug: false,
		whitelist: availableLanguages,
		interpolation: {
			escapeValue: false, // no need for react. it escapes by default
		},
	});

export default i18n;