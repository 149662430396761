import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHgri8EBQPe_3IwmHT4d2XeXvWNubcRsE",
  authDomain: "poolseries-4189f.firebaseapp.com",
  projectId: "poolseries-4189f",
  storageBucket: "poolseries-4189f.appspot.com",
  messagingSenderId: "70543578187",
  appId: "1:70543578187:web:190a5468aa7a3ee766e9ce"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }
