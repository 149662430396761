import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Container, Grid, Paper, Typography} from "@mui/material";
import AlertBox from "../../alert/AlertBox";
import {useTranslation} from "react-i18next";
import apiClient from "../../../services/apiClient";
import {useProtectedContext} from "../../../contexts/protectedContext";
import {NotificationAlert, SetGameCard, CardChart} from "components";
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import theme from '../../../theme'

function Predictions({roundId, title}) {
    const {t} = useTranslation()
    const {participantId} = useProtectedContext()
    const [gamePrediction, setGamePrediction] = useState([]);
    const [errors, setErrors] = useState({});

    const { isLoading, refetch: fetchUserPrediction } = useQuery(
      ['fetchUserPrediction', participantId],
      async () => {
          return await apiClient.fetchParticipantPrediction(participantId, roundId).then(({ data, error }) => {
              if (error) throw new Error(error)
              setGamePrediction(data)
          })
      },
      {
          enabled: false,
          onError: (error) => {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: t('app.ErrorSwal'),
                  confirmButtonColor: theme.palette.primary.light,
              })
          }
      }
    )

    useEffect(() => {
        setErrors({})
        fetchUserPrediction()
    }, [])

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('app.Error')}/>
            <Container maxWidth="lg" sx={{pt:5, pb:5}}>
                <Box>
                    <Paper sx={{p: 2,mb:5}} elevation={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{title}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AlertBox type="warning" message={t('game.WarningLimit')} />
                            </Grid>
                            {
                                gamePrediction?.games?.length > 0 &&
                                gamePrediction?.games.map((game, index) => {
                                    return (
                                        <Grid item xs={12} md={6} lg={4} key={index}>
                                            <SetGameCard game={game} roundLock={gamePrediction?.lock}/>
                                        </Grid>
                                    );
                                })
                            }
                            {
                                roundId === 1 &&
                                    <Grid item xs={12}>
                                        <AlertBox type="warning" message={t('game.SuperBowlBonusRecall')}/>
                                    </Grid>
                            }
                        </Grid>
                    </Paper>
                </Box>
                <CardChart roundId={roundId}/>
            </Container>
        </>
    );
}

export default Predictions;
