
import './asset/css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthContextProvider} from "./contexts/authContext";
import {
  Dashboard,
  Home,
  Login,
  ProtectedRoute,
  PublicRoute,
  RecoverPassword,
  ResetPassword,
  Rules,
  Register,
  Wildcard,
  Division,
  Conference,
  SuperBowl,
  BonusSuperBowl,
  Ranking, Predictions
} from './components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});
export default function AppContainer() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <App/>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthContextProvider>
    </QueryClientProvider>
  )
};

function App() {
  return (
      <div className="App" id="top-anchor">
        <BrowserRouter>
          <Routes>
                <Route exact path='/' element={<PublicRoute element={<Home/>} />} />
                <Route exact path='/login' element={<PublicRoute element={<Login/>} />} />
                <Route exact path="/recoverpassword/:token" element={<PublicRoute element={<RecoverPassword/>} />} />
                <Route exact path='/resetpassword' element={<PublicRoute element={<ResetPassword/>} />} />
                <Route exact path='/reglements' element={<PublicRoute element={<Rules />} />}/>
                <Route exact path='/inscription' element={<PublicRoute element={<Register />} />}  />
                <Route exact path='/unauthorized' element={<PublicRoute element={<Login message="app.MustBeAuthenticated"/>} />} />
                <Route exact path='/dashboard' element={<ProtectedRoute element={<Dashboard/>} />} />
                <Route exact path='/ranking' element={<ProtectedRoute element={<Ranking/>} />} />
                <Route exact path='/wildcard' element={<ProtectedRoute element={<Predictions roundId={1} title={'Wildcard'}/>} />} />
                <Route exact path='/division' element={<ProtectedRoute element={<Predictions roundId={2} title={'Division'}/>} />} />
                <Route exact path='/conference' element={<ProtectedRoute element={<Predictions roundId={3} title={'Conférence'}/>} />} />
                <Route exact path='/superbowl' element={<ProtectedRoute element={<Predictions roundId={4} title={'Super Bowl'}/>} />} />
                <Route exact path='/bonus' element={<ProtectedRoute element={<BonusSuperBowl/>} />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}
