import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {Button, Link} from "@mui/material";
import theme from '../../theme';

const listLanguages = ['en', 'fr'];

const TranslateLink = () => {
	const { t } = useTranslation();
	const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
	const [index, setIndex] = useState(listLanguages.indexOf(currentLanguage));

	const handleLangChange = () => {
		const newIndex =
			(index + 1) % listLanguages.length;
		i18next.changeLanguage(listLanguages[newIndex]);
		setCurrentLanguage(listLanguages[newIndex])
		setIndex(newIndex);
	};

	return (
		// <Button
		// 	onClick={handleLangChange}
		// 	sx={{ my: 2, color: 'white', display: 'block', minWidth:'auto' }}
		// >
		// 	{currentLanguage.toUpperCase()}
		// </Button>
		<></>
	);
}

export default TranslateLink;