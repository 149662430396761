import React, {useEffect, useState} from 'react';
import {Box, Fab, Fade, useScrollTrigger} from "@mui/material";
import {myStyles} from "../index";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function BackToTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 50,
    });

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#top-anchor"
        );
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1200 }}
            >
                <Fab size="large" aria-label="scroll back to top" sx={myStyles.backToTop}>
                    <KeyboardArrowUpIcon sx={{color:'white'}} />
                </Fab>
            </Box>
        </Fade>
    );
}

export default BackToTop;