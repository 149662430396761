import React, {useEffect} from 'react';
import MuiAlert from '@mui/material/Alert';
import {Snackbar, Stack} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NotificationAlert({type, message, openNotification}) {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(openNotification);
    } , [openNotification]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const getAlert = () => {
        switch (type) {
            case 'success':
                return <Alert onClose={handleClose} severity="success">{message}</Alert>;
            case 'error':
                return <Alert onClose={handleClose} severity="error">{message}</Alert>;
            case 'warning':
                return <Alert onClose={handleClose} severity="warning">{message}</Alert>;
            default:
                return <Alert onClose={handleClose} severity="info">{message}</Alert>;
        }
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                {
                    getAlert(type)
                }
            </Snackbar>
        </Stack>
    );
}

export default NotificationAlert;