import React from 'react';
import {useTranslation} from "react-i18next";
import {Container, Grid, List, ListItem, ListItemText, Typography} from "@mui/material";

function Rules(props) {
    const {t} = useTranslation();

    return (
        <Container maxWidth="lg" sx={{pt:5, pb:5}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h3" component="div">
                    {t('rules.Title')}
                </Typography>
                <List>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule1')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule2')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule3')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule4')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule5')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule6')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule7')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule7a')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule8')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Rule9')} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sx={{pt:5, pb:5}}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    {t('rules.CalculPointage')}
                </Typography>
                <List>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Pointage1')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Pointage2')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Pointage3')} secondary={t('rules.Pointage3a')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Pointage4')} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={t('rules.Pointage5')} secondary={t('rules.Pointage5a')} />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
        </Container>
    );
}

export default Rules;
