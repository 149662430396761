import theme from "../../theme";

const myStyles = {
    backToTop:{
        background: theme.palette.primary.dark,
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    card:{
        width: '100%',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color:'white'
    },
    cardIconDashboard:{
        fontSize:'60px',
    },
    gridIcons:{
        cursor:'pointer',
        fontSize:'2.5rem',
        color:theme.palette.primary.main,
        '&:hover':{
            color:theme.palette.primary.dark
        }
    },
    iconTitle:{
        fontSize:'3.5rem',
        color: theme.palette.primary.main,
        cursor:'pointer',
        "&:hover":{
            color:theme.palette.primary.dark
        }

    },
    loginContainer:{
        margin:"auto",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        padding: "100px 0",
    },
    loginLeftSide:{
        background: theme.palette.secondary.dark,
        minHeight: "500px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50px",
        padding:"50px",
        [theme.breakpoints.down('sm')]: {
            minHeight: "200px",
            padding:"20px",
        },
    },
    loginRightSide:{
        padding: "70px 0px 70px 24px",
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
        },
    },
    menuButton:{
        my: 2,
        color: 'white',
        background: 'transparent',
        display: 'block',
        "&:hover":{
            color:theme.palette.primary.light
        }
    },
    protectedContainer:{
        background:'white', marginTop: '65px',
        [theme.breakpoints.down('xl')]: {
            paddingLeft:"100px",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft:"10px",
        },
    }
};

export default myStyles;