import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {TranslateLink, PasswordRule, FormHelper} from 'components'
import apiClient from 'services/apiClient';
import logo from 'asset/images/logo.png'
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	InputAdornment,
	Link,
	Typography
} from "@mui/material";
import {BaseTextField} from '../../textfield/StyledTextField';
import theme from '../../../theme';
import PasswordValidator from 'password-validator';
import Swal from "sweetalert2";
import {Controller, useForm} from "react-hook-form";
import {validatePasswordSecurity} from "../../../utils/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const myStyles = {
	container: {
		margin:"auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		minHeight: "100vh",
		padding: "100px 0",
	},
};

function RecoverPassword({}) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isProcessing, setIsProcessing] = useState(false)
	const [errors, setErrors] = useState({})
    const {token} = useParams()
	const [showPassword, setShowPassword] = useState(false)
	const [pwdError, setPwdError] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])
	const [pwdMatch, setPwdMatch] = useState(false)
	const [isModifiedPassword, setIsModifiedPassword] = useState(false)

	const { control, getValues, handleSubmit } = useForm({
		reValidateMode: "onBlur"
	});

	useEffect(() => {
		setIsProcessing(true)
		apiClient.fetchUserFromTokenPassword(token).then(({data, error}) => {
			if (data){
				setIsModifiedPassword(data.isModified)
			}else{
				setErrors({msg: error})
			}
			setIsProcessing(false)
		})
	}, [])

	const handleShowPassword = () => setShowPassword(!showPassword);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleOnSubmit(event)
		}
	}

	const handleOnSubmit = async (password) => {
		if (pwdMatch && pwdError.length === 0) {
			setIsProcessing(true)
			apiClient.updatePassword({ password, token }).then(({ data, error }) => {
				if (error) {
					setErrors({ msg: error })
				}else{
					setIsProcessing(false)
					Swal.fire({
						title: t('auth.UpdatePasswordSuccess'),
						type: 'success',
						confirmButtonText: t('app.OK'),
						confirmButtonColor: theme.palette.secondary.dark,
					})
					navigate('/login')
				}
			}).catch(error => {
				setErrors({ msg: error })
			})
		}
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isProcessing}
			>
				<CircularProgress color="primary" size={40}/>
			</Backdrop>
			<Container maxWidth="lg" sx={myStyles.container}>
				<Grid container alignItems="center" justifyContent="center">
					{isModifiedPassword ? (
						<>
							<Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
								<Typography variant="h2">{t('auth.ResetPasswordUsed')}</Typography>
								<Typography gutterBottom>{t('auth.ResetPasswordUsedInfo')}</Typography>
								<Link href="/resetPassword">{t('auth.ResetPassword')}</Link>
							</Box>
						</> ) : (
							<Box display="flex" justifyContent="center"	alignItems="center" flexDirection="column" component="form" onSubmit={handleSubmit(handleOnSubmit)}>
								<Typography variant="h2">{t('auth.UpdatePassword')}</Typography>
								<Typography gutterBottom>{t('auth.UpdatePasswordInfo')}</Typography>
									<Controller
										control={control}
										name="password"
										defaultValue=""
										rules={{
											required: true
										}}
										render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
											<BaseTextField
												{...field}
												label={t('auth.Password')}
												fullWidth
												onChange={({ target: { value } }) =>{
													const { confirmPassword } = getValues();
													setPwdMatch(confirmPassword === value)
													onChange(value);
													setPwdError(validatePasswordSecurity(value))
												}}
												type={showPassword? 'text' : 'password'}
												variant="outlined"
												name="password"
												error={error !== undefined}
												helperText={error ? t(FormHelper.required[error.type]) : ""}
												InputProps={{
													endAdornment: (
														<InputAdornment
															onClick={handleShowPassword}
															className="showPassword"
															position="end">
															{showPassword ? <VisibilityIcon sx={{cursor:'pointer'}} /> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
														</InputAdornment>
													),
												}}
											/>
										)}
									/>
									<Controller
										control={control}
										name="confirmPassword"
										defaultValue=""
										rules={{
											required: true
										}}
										render={({  field: { ref, onChange, ...field }, fieldState: { error } }) => (
											<BaseTextField
												{...field}
												label={t('auth.PasswordConfirm')}
												fullWidth
												type={showPassword? 'text' : 'password'}
												variant="outlined"
												name="confirmPassword"
												onKeyDown={handleKeyDown}
												error={error !== undefined}
												helperText={error ? t(FormHelper.required[error.type]) : ""}
												onChange={({ target: { value } }) =>{
													const { password } = getValues();
													onChange(value);
													setPwdMatch(password === value)
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment
															onClick={handleShowPassword}
															className="showPassword"
															position="end">
															{showPassword ? <VisibilityIcon sx={{cursor:'pointer'}}/> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
														</InputAdornment>
													),
												}}
											/>
										)}
									/>
									<PasswordRule pwdRuleError={pwdError} pwdMatch={pwdMatch}/>
									<Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email}>
										{t('auth.ResetPassword')}
									</Button>
								</Box>
							)
						}
				</Grid>
			</Container>
		</>
	);
}

export default RecoverPassword;