import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Container, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import MUIDataTable from "mui-datatables";
import apiClient from "../../../services/apiClient";
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import theme from '../../../theme'

function Ranking() {
    const {t} = useTranslation()
    const [ranking, setRanking] = useState([]);

    const { isLoading, refetch: fetchRanking } = useQuery(
      'fetchRanking',
      async () => {
          return await apiClient.fetchRanking().then(({ data, error }) => {
              if (error) throw new Error(error)
              setRanking(data)
          })
      },
      {
          enabled: false,
          onError: (error) => {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: t('app.ErrorSwal'),
                  confirmButtonColor: theme.palette.primary.light,
              })
          }
      }
    )


    useEffect(() => {
        fetchRanking()
    }, []);

    const columns = [
        {
            name: '',
            label: '',
            options: {filter: false,
                customBodyRender : (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return ( <span>{rowIndex}</span> )
                },
                sort: false,
            },
        },
        {
            name: "user.fullName",
            label: "Nom",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "wildcard",
            label: "WildCard",
        },
        {
            name: "division",
            label: "Division",
        },
        {
            name: "conference",
            label: "Conference",
        },
        {
            name: "superbowl",
            label: "Super Bowl",
        },
        {
            name: "bonus",
            label: "Bonus",
        },
        {
            name: "totalPoints",
            label: "Total",
        },
    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        rowsPerPage:100,
        tableBodyHeight: '100%',
        tableBodyMaxHeight: '100%',
        rowsPerPageOptions: [],
        enableNestedDataAccess: '.',
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <Container maxWidth={"xl"} sx={{pt:3, pb:3, minHeight:'84.7vh'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    {t('ranking.Title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MUIDataTable
                                    data={ranking}
                                    columns={columns}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Ranking;
