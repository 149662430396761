import React from 'react';
import {useTranslation} from 'react-i18next';
import loginImg from '../../asset/images/hero.png'
import {Button, Typography} from "@mui/material";
import HeroLayout from "./HeroLayout";
import {useAuthContext} from "../../contexts/authContext";

function Hero(props) {
    const {t} = useTranslation();
    const { user } = useAuthContext()

    return (
        <HeroLayout>
            <Typography color="inherit" align="center" variant="h2" marked="center">
                {t('home.HeroTitre')}
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
            >
                {t('home.HeroDescription')}
            </Typography>
            {
                user?.email ?
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        component="a"
                        href="/dashboard"
                        sx={{ minWidth: 200 }}
                    >
                        {t('home.DashboardButton')}
                    </Button>
                :
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        component="a"
                        href="/inscription"
                        sx={{ minWidth: 200 }}
                    >
                        {t('home.HeroButton')}
                    </Button>
            }

        </HeroLayout>
    );
}

export default Hero;