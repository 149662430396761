import React, {useEffect, useState} from 'react';
import { Alert, Backdrop, Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import {Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis} from "recharts";
import apiClient from "../../../../services/apiClient";
import { useQuery } from 'react-query'

function BonusSuperBowlCardChart() {
    const [errors, setErrors] = useState(false);
    const [goingToSuperBowl, setGoingToSuperBowl] = useState([]);
    const [ winningSuperBowl, setWinningSuperBowl] = useState([]);

    const { isLoading, refetch: fetchStatsBonus } = useQuery(
      ['fetchStatsBonus'],
      async () => {
          return await apiClient.fetchStatsBonus().then(({ data, error }) => {
              if (error) throw new Error(error)
              setErrors(false)
              setGoingToSuperBowl(data.goingToSuperBowl)
              setWinningSuperBowl(data.goingToWinSuperBowl)
          })
      },
      {
          enabled: false,
          onError: (error) => {
              setErrors(true)
          }
      }
    )

    useEffect(() => {
        setErrors(false)
        fetchStatsBonus()
    }, [fetchStatsBonus])


    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <Paper sx={{p: 2,mb:5}} elevation={5}>
                <Grid container spacing={2}>
                    {
                      errors &&
                      <Grid item xs={12} sx={{pt: 2}}>
                          <Alert severity="error">Erreur lors de l'obtention des statisques</Alert>
                      </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h4">Statistiques</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{textAlign:'center'}}>
                                <Box>
                                    <Typography variant="h5">Prédiction qui va aller au superbowl</Typography>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={goingToSuperBowl}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <Tooltip />
                                        <YAxis hide />
                                        <Bar dataKey="value" >
                                            <LabelList dataKey="value" position="top" />
                                        </Bar>
                                    </BarChart>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{textAlign:'center'}}>
                                <Box>
                                    <Typography variant="h5">Prédiction qui va gagner le superbowl</Typography>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={winningSuperBowl}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="team" />
                                        <Tooltip />
                                        <YAxis hide />
                                        <Bar dataKey="value" >
                                            <LabelList dataKey="value" position="top" />
                                        </Bar>
                                    </BarChart>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default BonusSuperBowlCardChart;
