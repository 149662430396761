const PasswordValidator = require("password-validator");

const validEmail = (email) => {
	if (email.indexOf("@") === -1) {
		return {valid: false, error: "app.ValidEmailError" }
	} else {
		return {valid: true, error: null }
	}
}

const validatePasswordSecurity = (password) => {
	const pwdRule = new PasswordValidator();
	pwdRule
		.is()
		.min(8) // Minimum length 8
		.has()
		.uppercase() // Must have uppercase letters
		.has()
		.lowercase() // Must have lowercase letters
		.has()
		.digits(1) // Must have at least 1 digits
		.has()
		.symbols(1) // Must have at least 1 special character
		.has()
		.not()
		.spaces(); // Should not have spaces

	return pwdRule.validate(password, {list : true})
}


module.exports = {
	validatePasswordSecurity,
	validEmail,
}