import React from 'react';
import {Alert} from "@mui/material";

function AlertBox({type, message}) {
    const getAlert = () => {
        switch (type) {
            case 'success':
                return <Alert sx={{mb:1}}  severity="success">{message}</Alert>;
            case 'error':
                return <Alert sx={{mb:1}}  severity="error">{message}</Alert>;
            case 'warning':
                return <Alert sx={{mb:1}}  severity="warning">{message}</Alert>;
            default:
                return <Alert sx={{mb:1}}  severity="info">{message}</Alert>;
        }
    }

    return (
       getAlert(type)
    );
}

export default AlertBox;