import React, {useState} from 'react';
import loginImg from '../../asset/images/logo.png'
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {useAuthContext} from "../../contexts/authContext";
import {TranslateLink, myStyles} from "components";

const settings = [{page: 'logout', link:'/logout', name:'menu.Logout' }];
const pages = [{page: 'accueil', link:'/', name:'menu.Home' }, {page: 'reglements', link:'/reglements', name:'menu.Rules' }, {page: 'inscription', link:'/inscription', name:'menu.Register' }, {page: 'login', link:'/login', name:'menu.Login' }];
const pagesAuth = [{page: 'accueil', link:'/', name:'menu.Home' }, {page: 'reglements', link:'/reglements', name:'menu.Rules' },{page: 'dashboard', link:'/dashboard', name:'menu.Dashboard' }];


function HeaderPublic(props) {
    const { user, handleLogout } = useAuthContext()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleNavAvatar = (link) => {
        if (link === '/logout') {
            handleLogout()
        }else{
            navigate(link)
        }
        handleCloseNavMenu();
    }

    const handleNavigation = (link) => {
        navigate(link)
        handleCloseNavMenu();
    }

    const renderNavMenu = (isMobile) => {
        return (
            user?.email ? (
                pagesAuth.map((page) => (
                    isMobile ? (
                        <MenuItem key={page.page} onClick={() => handleNavigation(page.link)}>
                            <Typography textAlign="center">{t(page.name)}</Typography>
                        </MenuItem>
                    ):(
                        <Button
                            key={page.page}
                            onClick={() => handleNavigation(page.link)}
                            sx={myStyles.menuButton}
                        >
                            {t(page.name)}
                        </Button>
                    )
                ))
            ) : (
                pages.map((page) => (
                    isMobile ? (
                        <MenuItem key={page.page} onClick={() => handleNavigation(page.link)}>
                            <Typography textAlign="center">{t(page.name)}</Typography>
                        </MenuItem>
                    ):(
                        <Button
                            key={page.page}
                            onClick={() => handleNavigation(page.link)}
                            sx={myStyles.menuButton}
                        >
                            {t(page.name)}
                        </Button>
                    )
                ))
            )
        )
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, mb:1,mt:1, display: { xs: 'none', md: 'flex' } }}
                    >
                        <img src={loginImg} alt="PoolSeries" width="100px" />
                    </Typography>
                    {/*icon menu mobile*/}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            { renderNavMenu(true)}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, mb:1, mt:1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <img src={loginImg} alt="NessaApp" width="100px" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        { renderNavMenu(false)}
                    </Box>
                    {
                        user?.email ? (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar>{user.fullName.charAt(0)}</Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.page} onClick={() => handleNavAvatar(setting.link)}>
                                            <Typography textAlign="center">{t(setting.name)}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        ) : (
                            <>
                                <TranslateLink/>
                            </>
                        )
                    }

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default HeaderPublic;
